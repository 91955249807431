// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Components
import isEmpty from "../../validation/is-empty";
import EditProfile from "../edit-profile/EditProfile";
import Modal from "../modal/Modal";
import { ModalMultiple } from "../portfolio-edit/PortfolioEdit.components";
import AccountProfileEditProjects from "./AccountProfileEditProjects";

// Bring in Redux
import { connect } from "react-redux";

// API
import { useProjectsByUserId } from "../../api/permissions/PermissionsAPI";
import { deleteAccount, sendAdminRequest } from "../../api/users/UserAPI";
import { humanize } from "../../validation/humanize";

// Translation
import { translate } from "../../utils/translation";

// Functions
import { displayUserImage } from "../../validation/image-clean";

// Functions
import { hasRoles } from "../../utils/roles";
import Spinner from "../common/Spinner";
import { ImageDisplay } from "../images/ImageDisplay";

import "./AccountProfile.css";

AccountProfile.propTypes = {
  account: PropTypes.object,
  accounts: PropTypes.array,
  projects: PropTypes.array,
  show: PropTypes.bool,
  setShow: PropTypes.func,
  userIds: PropTypes.array,
  auth: PropTypes.object,
};

function AccountProfile(props) {
  const { account } = props;
  const { accounts } = props;
  const { projects } = props;
  const { show, setShow } = props;
  const { accountsProjects } = useProjectsByUserId(account.id, show);
  const { user } = props.auth;

  return (
    <div className="account-profile">
      <div className="account-profile-title-row">
        <ImageDisplay
          image={displayUserImage(account)}
          imageWidth={"100px"}
          imageHeight={"100px"}
          borderRadius={"50px"}
          isBezierDisabled={true}
        />
      </div>
      <div className="account-profile-details-container">
        <div className="account-profile-details">
          <DetailsRow label={translate("Name")} value={account.name} />
          <DetailsRow label={translate("Surname")} value={account.surname} />
          <DetailsRow label={translate("Email")} value={account.email} />
          <DetailsRow
            label={translate("Job Title")}
            value={account.job_title}
          />
          <DetailsRow
            label={translate("Company")}
            value={account.company_name}
          />
          <DetailsRow label={translate("Region")} value={account.region} />
          <DetailsRow label={translate("Country")} value={account.country} />
          <DetailsRow label={translate("City")} value={account.city} />
          <DetailsRow
            label={translate("Office Location")}
            value={account.office_location}
          />
          <DetailsRow
            label={translate("Phone Number")}
            value={account.phone_number}
          />
          <DetailsRow
            label={translate("Mobile Number")}
            value={account.mobile_number}
          />
          <DetailsRow label={translate("Linkedin")} value={account.linkedin} />
          <DetailsRow
            label={translate("Privacy Consent")}
            value={account.hasPrivacyConsent}
            type="boolean"
          />
        </div>

        <ProjectsList projects={accountsProjects} />
      </div>

      <div className="account-profile-buttons-row">
        <EditButton
          account={account}
          accounts={accounts}
          show={show}
          setShow={setShow}
          user={user}
        />
        <EditProjectsButton
          account={account}
          projects={projects}
          accountsProjects={accountsProjects}
        />
        <DeleteButton account={account} accounts={accounts} setShow={setShow} />
      </div>
    </div>
  );
}

function EditProjectsButton(props) {
  const { account } = props;
  const { projects } = props;
  const { accountsProjects } = props;

  return (
    <ModalMultiple
      // Modal Props
      Component={AccountProfileEditProjects}
      accountsProjects={accountsProjects}
      selectedData={accountsProjects.data}
      account={account}
      projects={projects}
      label={<i className="fas fa-city"></i>}
    />
  );
}

function EditButton(props) {
  const { account } = props;
  const { accounts } = props;
  const { show } = props;
  const { setShow } = props;
  const { user } = props;

  const [modal, setModal] = useState(false);

  if (!hasRoles(user.roles, ["LanguageAdmin"])) {
    return <div />;
  }

  return (
    <div>
      <Modal
        // Modal Props
        title={"Edit Profile"}
        Component={EditProfile}
        modal={modal}
        setModal={setModal}
        // Component Props
        account={account}
        user={account}
        sendRequest={sendAdminRequest}
        accounts={accounts}
        show={show}
        setShow={setModal}
        setParentModalShow={setShow}
        isAdmin={"true"}
      />
      <button
        className="general-upload-button"
        onClick={() => {
          setModal(true);
        }}
      >
        {"Edit Profile"}
      </button>
    </div>
  );
}

function DeleteButton(props) {
  const { account } = props;
  const { accounts } = props;
  const { setShow } = props;

  const data = {
    accountID: account.id,
    accounts: accounts,
    setShow: setShow,
  };

  return (
    <button
      className="account-profile-delete-button"
      onClick={() => {
        deleteAccount(data);
      }}
    >
      Delete Account
    </button>
  );
}

function ProjectsList(props) {
  const { projects } = props;
  const [filter, setFilter] = useState("");

  if (projects.loading) {
    return (
      <div className="account-profile-user-box">
        <div className="account-profile-user-title-row">
          <Spinner marginLeft={"100px"} marginTop={"100px"} />
        </div>
      </div>
    );
  }

  if (isEmpty(projects.data)) {
    return (
      <div className="account-profile-user-box">
        <div className="account-profile-user-title-row">
          <div className="display-4" style={{ fontSize: "25px" }}>
            {"No Projects"}
          </div>
        </div>
      </div>
    );
  }

  let filteredProjects = projects.data.filter((p) => {
    let projectName = p.title.toLowerCase();
    if (filter === "") {
      return p;
    }

    if (projectName.includes(filter.toLowerCase())) {
      return p;
    }
    return null;
  });

  let table = [];

  // Project Title Row
  table.push(
    <div key={0} className="account-profile-user-table-row">
      <div className="project-management-avatar" />
      <div className="account-profile-user-name">
        <b>Project Title</b>
      </div>
    </div>
  );

  // Project Rows
  filteredProjects.forEach((project, i) => {
    table.push(
      <div key={i + 1} className="account-profile-user-table-row">
        <div className="account-profile-user-name">{project.title}</div>
      </div>
    );
  });

  return (
    <div className="account-profile-user-box">
      <div className="account-profile-user-title-row">
        <div className="display-4" style={{ fontSize: "25px" }}>
          {"Projects"}
        </div>
        <input
          className="account-profile-user-filter"
          placeholder={"Filter Project Title"}
          value={filter}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        ></input>
      </div>

      {table}
    </div>
  );
}

function DetailsRow(props) {
  const { label, value, humanized, type } = props;

  if (isEmpty(value)) {
    return null;
  }

  let text = value;
  if (humanized) {
    text = humanize(value);
  }

  if (type === "boolean" && value) {
    text = "Yes";
  }

  if (type === "boolean" && !value) {
    text = "No";
  }

  return (
    <div className="account-profile-details-row">
      <div className="account-profile-details-label">
        <b>{label}</b>
      </div>
      <div className="account-profile-details-text">{text}</div>
    </div>
  );
}

export default connect((state) => ({
  auth: state.auth,
}))(AccountProfile);
