import { useState, useEffect } from "react";
import { authHeader } from "../../_helpers/auth-header";
import { sharedAPIs } from "../api-endpoints/SharedServiceEndpoints";
import { getDmsToken } from "../../active-directory/getDmsToken";
import isEmpty from "../../validation/is-empty";

export function useDocumentUri(documentId) {
  const [loading, setLoading] = useState(true);
  const [documentUri, setDocumentUri] = useState({});

  useEffect(() => {
    if (documentId) {
      const controller = new AbortController();
      const abortSignal = controller.signal;

      setLoading(true);
      setDocumentUri("");
      getDocumentUri(documentId, abortSignal)
        .then(async (res) => {
          if (!abortSignal.aborted) {
            const documentUrl = URL.createObjectURL(res);
            setDocumentUri(documentUrl);
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [documentId]);

  return {
    documentUri: {
      data: documentUri,
      loading: loading,
      get: getDocumentUri,
      set: setDocumentUri,
    },
  };
}

// Get all Projects available
export async function getDocumentUri(documentId, abortSignal) {
  const dmsToken = await getDmsToken();
  let dmsBearer = "";
  if (!isEmpty(dmsToken)) {
    dmsBearer = dmsToken.accessToken;
  }

  let url = sharedAPIs().getDocumentDownloadUri + "?documentId=" + documentId;

  const config = {
    method: "GET",
    headers: {
      ...authHeader({ authJson: true, authForm: false, guestJson: false }),
      dmsBearer: `Bearer ${dmsBearer}`,
    },
  };

  if (!isEmpty(abortSignal)) {
    config.signal = abortSignal;
  }

  let response = await fetch(url, config);
  if (response.ok) {
    return response.blob();
  } else {
    return "";
  }
}
