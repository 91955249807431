import React from "react";
import { Link } from "react-router-dom";
import { translate } from "../../utils/translation";
import PropTypes from "prop-types";

import Spinner from "../common/Spinner";
import isEmpty from "../../validation/is-empty";

PostContractNavigation.propTypes = {
  projectID: PropTypes.string,
  setShow: PropTypes.func,
  show: PropTypes.bool,
  setCPShow: PropTypes.func,
  setSustainabilityShow: PropTypes.func,
  postContractForm: PropTypes.object,
};

export default function PostContractNavigation(props) {
  const { projectID } = props;
  const { show, setShow } = props;
  const { setCPShow, setSustainabilityShow } = props;
  const { postContractForm } = props;

  let section = window.location.href.includes("post-contract");

  let name = "project-navigation-section";
  if (section) {
    name = "project-navigation-section-selected";
  }

  return (
    <div className="project-navigation-tab-item-group">
      <button
        className="project-navigation-tab-link"
        onClick={() => {
          setShow((state) => !state);
          setCPShow(() => false);
          setSustainabilityShow(() => false);
        }}
      >
        <div className={name}>
          <div className="project-navigation-label">
            <div className="front-icon">
              <div className="icon-container">
                <i
                  className="fas fa-file-contract"
                  style={{ marginRight: "10px" }}
                ></i>
              </div>
              {translate("Post Contract")}
            </div>
            {show && (
              <div className="icon">
                <i className="far fa-minus-square" />
              </div>
            )}
            {!show && (
              <div className="icon">
                <i className="far fa-plus-square" />
              </div>
            )}
          </div>
        </div>
      </button>

      <Tabs
        show={show}
        postContractForm={postContractForm}
        section={section}
        projectId={projectID}
      />
    </div>
  );
}

Tabs.propTypes = {
  section: PropTypes.bool,
  projectId: PropTypes.string,
  postContractForm: PropTypes.object,
  show: PropTypes.bool,
};

function Tabs(props) {
  const { section } = props;
  const { postContractForm } = props;
  const { projectId } = props;
  const { show } = props;

  if (!show) {
    return null;
  }

  if (isEmpty(postContractForm) || isEmpty(postContractForm.data)) {
    return null;
  }

  const tabs = [];
  postContractForm?.data?.sectionFields?.forEach((feature) => {
    tabs.push({
      name: feature.name,
      value: feature.value,
      key: feature.key,
      url: `/project/${projectId}/post-contract/${feature.key}`,
    });
  });

  const display = [];

  tabs.forEach((tab) => {
    if (tab.value === "True" || tab.value === "true" || tab.value === true) {
      display.push(<Tab key={tab.key} tab={tab} section={section} />);
    }
  });

  if (postContractForm.loading) {
    return <Spinner width={50} />;
  }

  return <div>{display}</div>;
}

Tab.propTypes = {
  section: PropTypes.bool,
  tab: PropTypes.object,
};

function Tab(props) {
  const { tab } = props;
  const { section } = props;

  let type = "cost-planning-tab-item";

  const path = window.location.href;
  const pathString = path.substring(path.lastIndexOf("/") + 1);
  let string = pathString.replaceAll("%20", " ");

  if (section && string === tab.key) {
    type = "cost-planning-tab-item-selected";
  }

  return (
    <Link key={tab.name} to={tab.url} className="cost-planning-tab-link">
      <div className={type}>
        <div className="cost-planning-tab-link-text">{translate(tab.name)}</div>
      </div>
    </Link>
  );
}
