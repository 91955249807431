import { useDocumentUri } from "../../api/dms/DmsAPI";
import PropTypes from "prop-types";
import "./ImageDisplay.css";
import Spinner from "../common/Spinner";

ImageDisplay.propTypes = {
  image: PropTypes.object,
};

export function ImageDisplay(props) {
  const { image } = props;

  if (image?.isApi) {
    return <ImageDisplayApi documentId={image.documentId} {...props} />;
  } else {
    // For Legacy Support
    return <ImageDisplayUri imageUri={image} {...props} />;
  }
}

ImageDisplayApi.propTypes = {
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  documentId: PropTypes.string,
  borderRadius: PropTypes.string,
  isBezierDisabled: PropTypes.bool,
  isContain: PropTypes.bool,
};

function ImageDisplayApi(props) {
  const { imageWidth, imageHeight } = props;
  const { borderRadius } = props;
  const { isBezierDisabled } = props;
  const { documentId } = props;
  const { documentUri } = useDocumentUri(documentId);
  const { isContain } = props;

  let classType = "image-display-bezier";

  if (isBezierDisabled) {
    classType = "image-display";
  }

  if (documentUri.loading) {
    return (
      <div
        className={"image-loading"}
        style={{
          height: imageHeight,
          width: imageWidth,
        }}
      >
        <Spinner width={"30px"} />
      </div>
    );
  }

  console.log("documentUri?.data", documentUri?.data);

  return (
    <div
      className={classType}
      style={{
        height: imageHeight,
        width: imageWidth,
        backgroundSize: isContain ? "contain" : "cover",
        backgroundImage: `url(${documentUri?.data})`, // NOSONAR false positive
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderRadius: borderRadius || "0px",
      }}
    ></div>
  );
}

ImageDisplayUri.propTypes = {
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
  imageUri: PropTypes.string,
  borderRadius: PropTypes.string,
  isBezierDisabled: PropTypes.bool,
  isContain: PropTypes.bool,
};

function ImageDisplayUri(props) {
  const { imageWidth, imageHeight } = props;
  const { imageUri } = props;
  const { borderRadius } = props;
  const { isBezierDisabled } = props;
  const { isContain } = props;

  let classType = "image-display-bezier";

  if (isBezierDisabled) {
    classType = "image-display";
  }

  return (
    <div
      className={classType}
      style={{
        height: imageHeight,
        width: imageWidth,
        backgroundSize: isContain ? "contain" : "cover",
        backgroundImage: `url(${imageUri})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        borderRadius: borderRadius || "0px",
      }}
    ></div>
  );
}
