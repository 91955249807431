//Translation
import { tranStr } from "../../utils/translation";

export function columns() {
  return [
    {
      heading: tranStr("First Name"),
      key: "name",
      type: "DESCRIPTION",
      width: 160,
      isFiltered: true,
    },
    {
      heading: tranStr("Surname"),
      key: "surname",
      type: "DESCRIPTION",
      width: 160,
      isFiltered: true,
    },
    {
      heading: tranStr("Job Title"),
      key: "jobTitle",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Email"),
      key: "email",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Region"),
      key: "region",
      type: "DESCRIPTION",
      width: 90,
      isFiltered: true,
    },
    {
      heading: tranStr("Country"),
      key: "country",
      type: "DESCRIPTION",
      width: 90,
      isFiltered: true,
    },
    {
      heading: tranStr("City"),
      key: "city",
      type: "DESCRIPTION",
      width: 90,
      isFiltered: true,
    },

    {
      heading: tranStr("Company"),
      key: "company_name",
      type: "DESCRIPTION",
      width: 120,
      isFiltered: true,
    },
    {
      heading: tranStr("Profile"),
      key: "hasProfile",
      type: "DESCRIPTION",
      width: 60,
      isFiltered: true,
    },
    {
      heading: tranStr("Created"),
      key: "timeCreated",
      type: "DATE",
      width: 160,
    },
    {
      heading: tranStr("Visited"),
      key: "timeVisited",
      type: "DATE",
      width: 160,
    },
  ];
}

export function benchmarkAccountsColumns(){
  return [
    {
      heading: tranStr("Name"),
      key: "name",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Surname"),
      key: "surname",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },    
    {
      heading: tranStr("Email"),
      key: "email",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Region"),
      key: "region",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Country"),
      key: "country",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("City"),
      key: "city",
      type: "DESCRIPTION",
      width: 200,
      isFiltered: true,
    },
    {
      heading: tranStr("Number of Saved Benchmark Configurations"),
      key: "collectionCount",
      type: "NUMBER",
      width: 160,
    },
  
  ];
}
