import React, { useState, useEffect } from "react";

import Spinner from "../common/Spinner";
import PropTypes from "prop-types";
import isEmpty from "../../validation/is-empty";
import "./PostContractPackageSelector.css";

PostContractPackageSelector.propTypes = {
  packages: PropTypes.object,
  selectedPackage: PropTypes.object,
  setSelectedPackage: PropTypes.func,
};

export function PostContractPackageSelector(props) {
  const { packages } = props;
  const { selectedPackage, setSelectedPackage } = props;
  //Both selectedoptions and selectedPackage are used to future proof the component for multi select
  const [selectedOptions, setSelectedOptions] = useState([selectedPackage]);
  const [isOpen, setIsOpen] = useState(false);

  // Set Default Package
  useEffect(() => {
    setSelectedOptions([selectedPackage]);
  }, [setSelectedOptions, selectedPackage]);

  if (packages.loading) {
    return <Spinner width={50} />;
  }

  if (isEmpty(selectedPackage) || isEmpty(selectedPackage.PackageName)) {
    return null;
  }

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    if (selectedOptions.includes(option)) {
      let selected = selectedOptions.filter((item) => item !== option);
      //This will make sure at least one option is selected at a time
      if (selected.length > 1) {
        setSelectedOptions(selected);
        setSelectedPackage(selected[0]);
      }
    } else {
      //For multi select in the future setSelectedOptions([...selectedOptions, option]);
      setSelectedPackage(option);
      setSelectedOptions([option]);
    }
    setIsOpen(false);
  };

  return (
    <div className="package-selector-container">
      <button className="package-selector-dropdown" onClick={toggleDropdown}>
        {!isEmpty(selectedOptions)
          ? selectedOptions.map((option) => option?.PackageName).join(", ")
          : "Select an option"}
        <div className="package-selector-dropdown-icon">
          {isOpen && <i className="fas fa-chevron-up"></i>}
          {!isOpen && <i className="fas fa-chevron-down"></i>}
        </div>
      </button>
      {isOpen && (
        <div className="package-selector-dropdown-options">
          {packages.data.map((option) => (
            <button
              key={option.Id}
              className="package-option"
              onClick={() => handleOptionClick(option)}
            >
              <input
                type="checkbox"
                key={option.Id}
                value={option.PackageName}
                checked={selectedOptions.includes(option)}
                onChange={() => handleOptionClick(option)}
              ></input>
              <span className="option-checkbox"></span>
              <div className="option-label">{option.PackageName}</div>
            </button>
          ))}
        </div>
      )}
    </div>
  );
}
