// Import React
import React, { useState, useEffect } from "react";

import isEmpty from "../../validation/is-empty";
import { editArticle } from "./MarketInsights.functions";

import {
  Heading,
  DisplayError,
} from "../tender-price-indexing/MarketInsights.components";

import { ImageUpload } from "../images/ImageUpload";
import { displayInsightImage } from "../../validation/image-clean";

export function ArticleEditModal(props) {
  const { selectedArticle } = props;
  const { show, setShow } = props;
  const { setInsights } = props;

  const [title, setTitle] = useState("");
  const [articleDate, setArticleDate] = useState("");
  const [docLink, setDocLink] = useState("");
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  const [imagePreview, setImagePreview] = useState(
    displayInsightImage(selectedArticle)
  );
  const [articleType, setArticleType] = useState("file");
  const [error, setError] = useState({});
  const { region, country } = props;
  const [global, setGlobal] = useState(false);

  let filesToggle;
  let linkToggle;

  //style changes according to toggle
  if (articleType === "file") {
    filesToggle = "mi-toggle-active-button";
    linkToggle = "mi-toggle-inactive-button";
  } else {
    linkToggle = "mi-toggle-active-button";
    filesToggle = "mi-toggle-inactive-button";
  }

  useEffect(() => {
    if (!isEmpty(selectedArticle)) {
      setTitle(selectedArticle.title);
      setFilePreview(selectedArticle.pdf);
      setDocLink(selectedArticle.document_link);
      setArticleType(selectedArticle.type);

      const newDate = new Date(parseInt(selectedArticle.timestamp));

      let day = newDate.toLocaleString("default", { day: "numeric" });
      let month = newDate.toLocaleString("default", { month: "numeric" });
      let year = newDate.toLocaleString("default", { year: "numeric" });
      let dateString = day + "/" + month + "/" + year;

      setArticleDate(dateString);
      setGlobal(selectedArticle.global);
    }
  }, [selectedArticle, show]);
  const data = {
    image,
    title,
    articleDate,
    articleType,
    docLink,
    file,
    selectedArticle,
    region,
    country,
    global,
  };

  return (
    <div className="category-edit">
      <Heading title={title} />
      <div className="general-row-container">
        <ImageUpload
          imagePreview={imagePreview}
          setImage={setImage}
          setImagePreview={setImagePreview}
          imageWidth={"320px"}
          imageHeight={"300px"}
        />
      </div>
      <div className="general-row-container">
        <div className="category-edit-field">
          <b>Article Title</b>
        </div>
        <input
          id={"article-title-input"}
          className="category-title-input"
          value={title}
          placeholder={"Title"}
          onChange={(e) => {
            setTitle(e.target.value);
          }}
        />
      </div>
      <div className="general-row-container">
        <div className="category-edit-field">
          <b>Article Date (dd/mm/yyyy)</b>
        </div>
        <input
          id={"article-date-input"}
          className="category-title-input"
          value={articleDate}
          placeholder={"dd/mm/yyyy"}
          onChange={(e) => {
            setArticleDate(e.target.value);
          }}
        />
      </div>
      <div className="general-row-container">
        <div className="category-edit-field">
          <b>Global Article</b>
        </div>
        <div className="category-title-input">
          <input
            id={"article-global-input"}
            type="checkbox"
            checked={global}
            onChange={(e) => {
              setGlobal(e.target.checked);
            }}
          />
        </div>
      </div>

      <div className="post-contract-clarifications">
        <div className="post-contract-toggle">
          <button
            id={"article-file-button"}
            className={filesToggle}
            onClick={async () => {
              setArticleType("file");
            }}
          >
            File
          </button>
          <button
            id={"article-link-button"}
            className={linkToggle}
            onClick={async () => {
              setArticleType("link");
            }}
          >
            Document Link
          </button>
        </div>
      </div>
      {articleType === "file" && (
        <div>
          <div className="general-row-container">
            <UploadFile
              setFunction={setFile}
              name={"Add File"}
              setFilePreview={setFilePreview}
            />
          </div>
          <div className="general-row-container">
            <FileTray file={filePreview} />
          </div>
        </div>
      )}
      {articleType === "link" && (
        <div className="general-row-container">
          <input
            id={"article-link-input"}
            className="article-link"
            value={docLink}
            placeholder={"Document link"}
            onChange={(e) => {
              setDocLink(e.target.value);
            }}
          />
        </div>
      )}
      {error.text && (
        <div className="general-row-container">
          <DisplayError error={error} setError={setError} />
        </div>
      )}
      <div className="general-button-container">
        <button
          className="general-upload-button"
          id={"article-edit-button"}
          onClick={async () => {
            const setFunctions = {
              setInsights: setInsights,
              setShow: setShow,
              setImagePreview: setImagePreview,
              setFilePreview: setFilePreview,
              setError: setError,
            };
            await editArticle(data, setFunctions);
          }}
        >
          Update
        </button>
      </div>
    </div>
  );
}

function FileTray(props) {
  const { file } = props;
  let fileName = "No File Added";

  if (file !== null && file !== undefined) {
    fileName = file.name;
  }

  if (typeof file === "string") {
    fileName = "No Changes";
  }

  return <div className="category-edit-file-display">File: {fileName}</div>;
}

function UploadFile(props) {
  const { setFunction, name } = props;
  const { setFilePreview } = props;

  return (
    <div className="upload-buttons-container">
      <label className="general-modal-button">
        <input
          id="article-edit-file"
          type="file"
          className="category-edit-button"
          onChange={(e) => {
            if (e.target.files && e.target.files.length > 0) {
              setFunction(e.target.files[0]);
              setFilePreview(e.target.files[0]);
            }
          }}
        />
        {name}
      </label>
    </div>
  );
}
