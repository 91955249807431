import React from "react";
import { useBenchmarkWithRows } from "../../api/benchmarking/BenchmarkingAPI";
import PropTypes from "prop-types";

import "./BenchmarkView.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import BenchmarkProjectInfoView from "./BenchmarkProjectInfoView";
import BenchmarkNotesView from "./BenchmarkNotesView";
import { BenchmarkElementAnalysisView } from "./BenchmarkElementAnalysisView";
import BenchmarkMetricsView from "./BenchmarkMetricsView";
import isEmpty from "../../validation/is-empty";
import BenchmarkCostPlanView from "./BenchmarkCostPlanView";
import Spinner from "../common/Spinner";
import { BA_CBS_ROLE, hasRoles } from "../../utils/roles";
import BenchmarkVisualsView from "./BenchmarkVisualsView";
import { BasicBenchmark } from "./Benchmark.components";

BenchmarkViewWrapper.propTypes = {
  selectedRow: PropTypes.object,
  user: PropTypes.object,
};

export function BenchmarkViewWrapper(props) {
  const { selectedRow } = props;
  const { user } = props;
  const tabNames = [
    "projectInfo",
    "elementAnalysis",
    "benchmarks",
    "notes",
    "Cost Plan",
    "Visuals",
  ];

  const result = useBenchmarkWithRows(selectedRow.id);
  const [tabValue, setTabValue] = React.useState(tabNames[0]);

  let rowData = {};

  if (result?.benchmarkWithRows?.loading) {
    return <Spinner width={"150px"} />;
  }

  if (!isEmpty(result?.benchmarkWithRows?.data)) {
    rowData = result?.benchmarkWithRows?.data[0];
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box className="bm-full-width-box-container">
      <Box className="bm-tab-box-container">
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          centered
          aria-label="Benchmark view tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: "var(--heritage)",
            },
          }}
        >
          <Tab
            label="Project Information"
            value={tabNames[0]}
            style={{ outline: "none" }}
          />
          <Tab
            label="Element Analysis"
            value={tabNames[1]}
            style={{ outline: "none" }}
          />
          <Tab
            label="Benchmarks"
            value={tabNames[2]}
            style={{ outline: "none" }}
          />
          <Tab label="Notes" value={tabNames[3]} style={{ outline: "none" }} />
          {hasRoles(user.roles, BA_CBS_ROLE) && (
            <Tab
              label="Cost Plan"
              value={tabNames[4]}
              style={{ outline: "none" }}
            />
          )}
          <Tab
            label="Visuals"
            value={tabNames[5]}
            style={{ outline: "none" }}
          />
        </Tabs>
      </Box>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[0]}>
        <BenchmarkProjectInfoView rowData={rowData} />
      </BenchmarkCustomTabPanel>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[1]}>
        <BenchmarkElementAnalysisView rowData={rowData} />
      </BenchmarkCustomTabPanel>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[2]}>
        <BenchmarkMetricsView rowData={rowData?.metrics} />
      </BenchmarkCustomTabPanel>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[3]}>
        <BenchmarkNotesView rowData={rowData} />
      </BenchmarkCustomTabPanel>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[4]}>
        <BenchmarkCostPlanView rowData={rowData} />
      </BenchmarkCustomTabPanel>
      <BenchmarkCustomTabPanel value={tabValue} index={tabNames[5]}>
        <BenchmarkVisualsView rowData={rowData} />
      </BenchmarkCustomTabPanel>
    </Box>
  );
}

export function BenchmarkCustomTabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      className="tabPanel"
      role="tabpanel"
      hidden={value !== index}
      id={`bm-tabpanel-${index}`}
      aria-labelledby={`bm-tab-${index}`}
    >
      {value === index && <>{children}</>}
    </div>
  );
}
BenchmarkView.propTypes = {
  caller: PropTypes.string,
};
export default function BenchmarkView(props) {
  const { caller } = props;
  return (
    <div>
      {caller === "BASIC_BENCHMARK_CREATE" && <BasicBenchmark {...props} />}
      {isEmpty(caller) && <BenchmarkViewWrapper {...props} />}
    </div>
  );
}

