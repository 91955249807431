import isEmpty from "../../validation/is-empty";
import { tranStr } from "../../utils/translation";

function validateField(value) {
  return value ? value.toLowerCase() : "";
}

function isBenchmarkVisible(projectID, exclusiveID) {
  // No exclusive ID so we want to display this project
  if (isEmpty(exclusiveID)) {
    return true;
  }

  // No Project ID so we do not want to display this exclusive Benchmark
  if (isEmpty(projectID)) {
    return false;
  } else {
    // There is a project ID, does it match the exclusive ID
    return projectID.toString() === exclusiveID.toString();
  }
}

function isBenchmarkToggled(isFiltered, exclusiveID) {
  if (isEmpty(exclusiveID) && !isFiltered) {
    return true;
  }

  if (isEmpty(exclusiveID) && isFiltered) {
    return false;
  }

  if (!isEmpty(exclusiveID) && isFiltered) {
    return true;
  }

  if (!isEmpty(exclusiveID) && !isFiltered) {
    return false;
  }
}

function filterBenchmarks(projectID, benchmarks, filter) {
  if (isEmpty(benchmarks)) {
    return [];
  }

  return benchmarks.filter((p) => {

    // Get Project Details
    const title = validateField(p.project_name);
    const standard = validateField(p.standard);
    const clientCompany = validateField(p.client);
    const architect = validateField(p.architect_designer);
    const sector = validateField(p.sector);
    const subsector = validateField(p.sub_sector);
    const projectType = validateField(p.project_type);
    const projectQuality = validateField(p.quality);
    const projectStage = validateField(p.project_stage);
    const procurementMethod = validateField(p.procurement_method);
    const region = validateField(p.region);
    const country = validateField(p.country);
    const city = validateField(p.city);
    const tpiLocation = validateField(p.city);
    const unionStatus = validateField(p.union_status);
    const contractor = validateField(p.main_contractor);
    const basis = validateField(p.analysis_basis);
    let baseYear = parseInt(new Date(p.base_date).getFullYear());
    baseYear = baseYear ===1 ? 1900 : baseYear;

    // Get Project Costs
    const GFA = parseInt(p.local_region_area) || 0;
    const constructionCost = parseInt(p.construction_cost) || 0;
    const projectCost = parseInt(p.project_cost) || 0;
    const projectRate = parseInt(p.construction_cost_rate) || 0;

    // Check Exclusivity ID
    const exclusiveProjectIdCheck = isBenchmarkVisible(
      projectID,
      p.exclusiveProjectId
    );

    const toggledCheck = isBenchmarkToggled(
      filter.isQuickBenchmark,
      p.exclusiveProjectId
    );

    // Check Details
    const titleCheck = title.includes(filter.title);
    const standardCheck = standard.includes(filter.standard);

    const clientCompanyCheck = clientCompany.includes(filter.clientCompany);
    const architectCheck = architect.includes(filter.architect);

    const sectorCheck = sector.includes(filter.sector);
    const subsectorCheck = subsector.includes(filter.subsector);

    const projectTypeCheck = projectType.includes(filter.projectType);
    const projectQualityCheck = projectQuality.includes(filter.projectQuality);
    const projectStageCheck = projectStage.includes(filter.projectStage);
    const procurementCheck = procurementMethod.includes(
      filter.procurementMethod
    );

    const regionCheck = region.includes(filter.region);
    const countryCheck = country.includes(filter.country);
    const cityCheck = city.includes(filter.city);
    const tpiLocationCheck = tpiLocation.includes(filter.tpiLocation);
    const unionStatusCheck = unionStatus.includes(filter.unionStatus);
    const contractorCheck = contractor.includes(filter.contractor);
    const basisCheck = basis.includes(filter.basis);
    const baseYearCheck = ( baseYear >= filter.baseYear);

    // Check Cost
    const minGFACheck = GFA >= filter.minGFA;
    const maxGFACheck = GFA <= filter.maxGFA;
    const minConstructionCheck = constructionCost >= filter.minConstructionCost;
    const maxConstructionCheck = constructionCost <= filter.maxConstructionCost;
    const minProjectCheck = projectCost >= filter.minProjectCost;
    const maxProjectCheck = projectCost <= filter.maxProjectCost;
    const minConstructionRateCheck = projectRate >= filter.minConstructionRate;
    const maxConstructionRateCheck = projectRate <= filter.maxConstructionRate;

    // The Search Filter
    if (
      toggledCheck &&
      exclusiveProjectIdCheck &&
      // Filter Details
      titleCheck &&
      standardCheck &&
      clientCompanyCheck &&
      architectCheck &&
      sectorCheck &&
      subsectorCheck &&
      projectTypeCheck &&
      projectQualityCheck &&
      projectStageCheck &&
      procurementCheck &&
      basisCheck &&
      //locationCheck &&
      regionCheck &&
      countryCheck &&
      cityCheck &&
      tpiLocationCheck &&
      unionStatusCheck &&
      contractorCheck &&
      // Filter Cost
      minGFACheck &&
      maxGFACheck &&
      minConstructionCheck &&
      maxConstructionCheck &&
      minProjectCheck &&
      maxProjectCheck &&
      minConstructionRateCheck &&
      maxConstructionRateCheck &&
      baseYearCheck
    ) {
      return p;
    } else {
      return null;
    }
  });
}

function procurementList() {
  return [
    { label: tranStr("All Procurements"), value: "" },
    {
      label: tranStr("Lump Sum - Construct Only"),
      value: "Lump Sum - Construct Only",
    },
    {
      label: tranStr("Design and Construct"),
      value: "Design and Construct",
    },
    {
      label: tranStr("Construction Management"),
      value: "Construction Management",
    },
    {
      label: tranStr("Managing Contractor"),
      value: "Managing Contractor",
    },
    {
      label: tranStr("GMP"),
      value: "GMP",
    },
  ];
}

function typeList() {
  return [
    { label: tranStr("All Types"), value: "" },
    {
      label: tranStr("New Build"),
      value: "New Build",
    },
    {
      label: tranStr("Fit-Out"),
      value: "Fit-Out",
    },
    {
      label: tranStr("Refurbishment"),
      value: "Refurbishment",
    },
    {
      label: tranStr("Extension"),
      value: "Extension",
    },
  ];
}

function sectorList() {
  return [
    { label: tranStr("All Sectors"), value: "" },
    {
      label: tranStr("Commercial"),
      value: "Commercial",
    },
    {
      label: tranStr("Commercial Retail"),
      value: "Commercial_Retail",
    },
    {
      label: tranStr("Education, Scientific, Information"),
      value: "Education_Scientific_Information",
    },
    {
      label: tranStr("Health"),
      value: "Health",
    },
    {
      label: tranStr("Industrial"),
      value: "Industrial",
    },
    {
      label: tranStr("Protective Facilities"),
      value: "Protective_Facilities",
    },
    {
      label: tranStr("Recreational"),
      value: "Recreational",
    },
    {
      label: tranStr("Recreational Sport"),
      value: "Recreational_Sport",
    },
    {
      label: tranStr("Religious"),
      value: "Religious",
    },
    {
      label: tranStr("Residential"),
      value: "Residential",
    },
    {
      label: tranStr("Short Stay"),
      value: "Short_Stay",
    },
    {
      label: tranStr("Transport"),
      value: "Transport",
    },
  ];
}

function subsectorList() {
  return [
    { label: tranStr("All Subsectors"), value: "", type: "" },
    {
      label: tranStr("Offices"),
      value: "Offices",
      type: "Commercial",
    },
    {
      label: tranStr("Workplace / Fit-Out"),
      value: "Workplace / Fit-Out",
      type: "Commercial",
    },
    {
      label: tranStr("Department Stores"),
      value: "Commercial",
      type: "Commercial Retail",
    },
    {
      label: tranStr("Shoppping Centres"),
      value: "Commercial",
      type: "Commercial Retail",
    },
    {
      label: tranStr("Speciality Shops / Malls"),
      value: "Commercial",
      type: "Commercial Retail",
    },
    {
      label: tranStr("College Buildings"),
      value: "College Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Libraries / Municipal Buildings"),
      value: "Libraries / Municipal Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Primary School Buildings"),
      value: "Primary School Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Research Institutions / Laboratories"),
      value: "Research Institutions / Laboratories",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Secondary School Buildings"),
      value: "Secondary School Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Student Accommodation"),
      value: "Student Accommodation",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("University Buildings"),
      value: "University Buildings",
      type: "Education, Scientific, Information",
    },
    {
      label: tranStr("Aged Care Facilities"),
      value: "Aged Care Facilities",
      type: "Health",
    },
    {
      label: tranStr("Health Centres / Medical Suites"),
      value: "Health Centres / Medical Suites",
      type: "Health",
    },
    {
      label: tranStr("Hospitals"),
      value: "Hospitals",
      type: "Health",
    },
    {
      label: tranStr("Vetinary Hospitals and Animal Clinics"),
      value: "Vetinary Hospitals and Animal Clinics",
      type: "Health",
    },
    {
      label: tranStr("Data Centres"),
      value: "Data Centres",
      type: "Industrial",
    },
    {
      label: tranStr("Factories"),
      value: "Factories",
      type: "Industrial",
    },
    {
      label: tranStr("Logistics Park"),
      value: "Logistics Park",
      type: "Industrial",
    },
    {
      label: tranStr("Warehouse Units / Stores"),
      value: "Warehouse Units / Stores",
      type: "Industrial",
    },
    {
      label: tranStr("Fire Stations / Ambulance Stations / Police Stations"),
      value: "Fire Stations / Ambulance Stations / Police Stations",
      type: "Protective Facilities",
    },
    {
      label: tranStr("Law Courts"),
      value: "Law Courts",
      type: "Protective Facilities",
    },
    {
      label: tranStr("Prisons"),
      value: "Prisons",
      type: "Protective Facilities",
    },
    {
      label: tranStr(
        "Convention Centres / Cultural Centres / Auditoriums / Art Galleries"
      ),
      value:
        "Convention Centres / Cultural Centres / Auditoriums / Art Galleries",
      type: "Recreational",
    },
    {
      label: tranStr("Performing Arts Entities / Theatres / Cinemas"),
      value: "Performing Arts Entities / Theatres / Cinemas",
      type: "Recreational",
    },
    {
      label: tranStr("Restaurants / Pubs / Clubs"),
      value: "Restaurants / Pubs / Clubs",
      type: "Recreational",
    },
    {
      label: tranStr("Theme Parks and Resorts"),
      value: "Theme Parks and Resorts",
      type: "Recreational",
    },
    {
      label: tranStr("Sports Centre"),
      value: "Sports Centre",
      type: "Recreational Sport",
    },
    {
      label: tranStr("Sports Fields"),
      value: "Sports Fields",
      type: "Recreational Sport",
    },
    {
      label: tranStr("Stadia"),
      value: "Stadia",
      type: "Recreational Sport",
    },
    {
      label: tranStr("Swimming Pools"),
      value: "Swimming Pools",
      type: "Recreational Sport",
    },
    {
      label: tranStr("Places of Worship"),
      value: "Places of Worship",
      type: "Religious",
    },
    {
      label: tranStr("Places of Worship - Other"),
      value: "Places of Worship - Other",
      type: "Religious",
    },
    {
      label: tranStr("Apartments"),
      value: "Apartments",
      type: "Residential",
    },
    {
      label: tranStr("Single and Double Storey Dwellings"),
      value: "Single and Double Storey Dwellings",
      type: "Residential",
    },
    {
      label: tranStr("Townhouses"),
      value: "Townhouses",
      type: "Residential",
    },
    {
      label: tranStr("Hotels - 3-Star"),
      value: "Hotels - 3-Star",
      type: "Short Stay",
    },
    {
      label: tranStr("Hotels - 4-Star"),
      value: "Hotels - 4-Star",
      type: "Short Stay",
    },
    {
      label: tranStr("Hotels - 5+ Star"),
      value: "Hotels - 5+ Star",
      type: "Short Stay",
    },
    {
      label: tranStr("Aviation - Airport Terminal"),
      value: "Aviation - Airport Terminal",
      type: "Transport",
    },
    {
      label: tranStr("Car Parking"),
      value: "Car Parking",
      type: "Transport",
    },
    {
      label: tranStr("Rail - Train Stations"),
      value: "Rail - Train Stations",
      type: "Transport",
    },
  ];
}

function qualityList() {
  return [
    { label: tranStr("All Qualities"), value: "" },
    {
      label: tranStr("Premium"),
      value: "Premium",
    },
    {
      label: tranStr("High"),
      value: "High",
    },
    {
      label: tranStr("Medium"),
      value: "Medium",
    },
    {
      label: tranStr("Basic"),
      value: "Basic",
    },
  ];
}

export {
  filterBenchmarks,
  sectorList,
  subsectorList,
  procurementList,
  typeList,
  qualityList  
};
export function getBaseYearCollection(benchmarking) {
  let baseYear = benchmarking.map(item => new Date(item.base_date).getFullYear());
  baseYear.push(new Date().getFullYear());
  baseYear.sort();
  let sortedBaseYears = [...new Set(baseYear)];
  let sortedBaseYear = [];
  sortedBaseYears.forEach(
    item => sortedBaseYear.push({ label: item, value: parseInt(item) })
  );
  sortedBaseYear.unshift({ label: "All Years", value: new Date("1-1-1900").getFullYear() });
  return sortedBaseYear;
}
