import React, { useState } from "react";
import "./BenchmarkProjectInfoView.css";
import isEmpty from "../../validation/is-empty";
import PropTypes from "prop-types";
import Table from "../table/Table";
import { tranStr } from "../../utils/translation";
import { useBenchmarkComparisonsForBenchmark } from "../../api/benchmarking/BenchmarkingAPI";
import Spinner from "../common/Spinner";
import {costPlanningColumns} from "./BenchmarkControlTable.functions"

BenchmarkCostPlanView.propTypes = {
  rowData: PropTypes.object,
};
export default function BenchmarkCostPlanView(props) {
  const { rowData } = props;
  const [selectedRow, setSelectedRow] = useState({});
  const [ setModal] = useState(false);

  const result = useBenchmarkComparisonsForBenchmark(rowData?.id);

  if (isEmpty(rowData) || result.comparisonBenchmarks.loading)
    return <Spinner width={"150px"} />;

  if (isEmpty(result.comparisonBenchmarks.data)) {
    return (
      <div className="no-data-available">
        <i className="fas fa-thumbs-down" /> No Associated Benchmarks available
      </div>
    );
  }

  return (
    <div className="create-benchmark-container">
      <div className="create-benchmark-form-container">
        {/* <div className="create-project-panel create-project-matrix-panel custom-margin"> */}
          <div className="cost-plan-view">
            <Table
              title={tranStr("Benchmark Cost Plans")}
              tableArray={result.comparisonBenchmarks.data}
              columns={costPlanningColumns()}
              tableSize={result.comparisonBenchmarks.data?.length}
              isTitleDisabled={true}
              isTotalDisabled={true}
              isPaginationDisabled={true}
              isFilterDisabled={false}
              // Row Selection
              setModal={setModal}
              selectedRow={selectedRow}
              setSelectedRow={setSelectedRow}
              isMenuAvailable={true}
            />
          </div>
        </div>
      </div>
    // </div>
  );
}


