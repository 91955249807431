// React
import React, { useState } from "react";

//Components
import SystemDashboardToggle from "./SystemDashboardToggle";
import { useSystemDashboardProject } from "../../api/projects/ProjectAPI";
import SystemDashboardCostPlanTable from "./SystemDashboardCostPlanTable";
import SystemDashboardCostReportTable from "./SystemDashboardCostReportTable";
import ExportSystemDashboard from "./ExportSystemDashboard";
import "./SystemDashboard.css";

// Functions
import { countReports, getPortfolioCountForEachUser } from "./SystemDashboard.functions";
import { useAccounts } from "../../api/users/UserAPI";
import AccountsTable from "../admin-accounts/AccountsTable";
import { useBenchmarkingPortfolio } from "../../api/portfolio/PortfolioAPI";

export default function SystemDashboard() {
  const [inputMode, setInputMode] = useState("COSTPLAN");
  const { project, projectLoading } = useSystemDashboardProject();
  const { totalCostPlans, totalCostReports } = countReports(project);
  const { accounts } = useAccounts();
  const {benchmarkingPortfolio}=useBenchmarkingPortfolio();
  const [setSelected] = useState([]);

  if (projectLoading  && benchmarkingPortfolio.loading) {
    return <div>Loading Project Report data. This may take a while...</div>;
  }

  getPortfolioCountForEachUser(accounts.data,benchmarkingPortfolio.data);

  return (
    <div className="alc-container">
      {/* Toggle between CostPlan and costReport */}
      <SystemDashboardToggle
        inputMode={inputMode}
        setInputMode={setInputMode}
      />
      <div className="alc-column">
        {inputMode === "COSTPLAN" && (
          <div>
            <div className="toggle-button-text-container">
              <p>
                <b>Project Cost Plan</b>
              </p>
              <p>
                <b>Total Cost Plans: {totalCostPlans}</b>
              </p>
              <SystemDashboardCostPlanTable
                data={project}
                dataLoading={projectLoading}
                setSelectedUsers={setSelected}
              />
              <ExportSystemDashboard projects={project} inputMode={inputMode} />
            </div>
          </div>
        )}
        {inputMode === "COSTREPORT" && (
          <div>
            <div className="toggle-button-text-container">
              <p>
                <b>Project Cost Report</b>
              </p>
              <p>
                <b>Total Cost Reports: {totalCostReports}</b>
              </p>
              <SystemDashboardCostReportTable
                data={project}
                dataLoading={projectLoading}
                setSelectedUsers={setSelected}
              />
              <ExportSystemDashboard projects={project} inputMode={inputMode} />
            </div>
          </div>
        )}
        {inputMode === "BENCHMARKING" && (
          <div>
            <div className="toggle-button-text-container">
              <p>
                <b>Benchmarking Analysis</b>
              </p>
              <AccountsTable accounts={accounts}/>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
