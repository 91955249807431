import React from "react";
import "./SystemDashboard.css";

export default function SystemDashboardToggle(props) {
  const { inputMode, setInputMode } = props;

  let costPlanToggle;
  let costReportToggle;
  let benchmarkingToggle;

  if (inputMode === "COSTPLAN") {
    costPlanToggle = "toggle-active-button";
    costReportToggle = "toggle-inactive-button";
    benchmarkingToggle = "toggle-inactive-button";
  }
  if (inputMode === "COSTREPORT") {
    costPlanToggle = "toggle-inactive-button";
    costReportToggle = "toggle-active-button";
    benchmarkingToggle = "toggle-inactive-button";
  }
  if (inputMode === "BENCHMARKING") {
    costPlanToggle = "toggle-inactive-button";
    costReportToggle = "toggle-inactive-button";
    benchmarkingToggle = "toggle-active-button";
  }
  return (
    <div className="systemDashboard-toggle">
      <button
        type="button"
        className={costPlanToggle}
        onClick={async () => {
          setInputMode("COSTPLAN");
        }}
      >
        Cost Plan
      </button>
      <button
        type="button"
        className={costReportToggle}
        onClick={async () => {
          setInputMode("COSTREPORT");
        }}
      >
        Cost Report
      </button>
      <button
        type="button"
        className={benchmarkingToggle}
        onClick={async () => {
          setInputMode("BENCHMARKING");
        }}
      >
        Benchmarking
      </button>
    </div>
  );
}
