import React, { useEffect, useState } from "react";
import { selectType } from "./BenchmarkUpload";
import {
  useBenchmarksWithRows,
  useSensitivityLevelCodes,
} from "../../api/benchmarking/BenchmarkingAPI";
import { generateBenchmarkInfoExcel } from "../../utils/spreadsheets";
import {
  deleteBenchmarking,
  saveBenchmarkCoordinates,
  saveFormBasedBenchmark,
  saveSensitivityCompanyId,
  saveSensitivityInformationFlag,
} from "../../api/benchmarking-control/BenchmarkingControlAPI";
import {
  featureEnumerator,
  prepareObjectForExcelUpload,
} from "./Benchmark.functions";
import {
  Field,
  FieldNumber,
  Label,
  ModalCompany,
} from "../project-create/CreateProject.components";
import CreateProjectSelectClientCompany from "../project-create/CreateProjectSelectClientCompany";
import PropTypes from "prop-types";
import { CreateProjectMap } from "../project-create/CreateProjectMap";
import { tranStr, translate } from "../../utils/translation";
import { BenchmarkCustomTabPanel } from "./BenchmarkView";
import { Box, Tab, Tabs } from "@mui/material";
import {
  CitySelect,
  CountrySelect,
  RegionSelect,
} from "../locations/LocationSelect";
import { useLocations } from "../../api/admin/RegionManagementAPI";
import isEmpty from "../../validation/is-empty";

export function BenchmarkInputSingle(props) {
  const { value, onChange, placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "input-icon";
  if (focus || value !== "") iconType = "input-icon-focus";

  return (
    <div className="input-container">
      <div className={iconType}>{icon}</div>
      <div className="input-col">
        {placeholder}
        <div className="input-row">
          <input
            className="b-c-search-input"
            type="text"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
        </div>
      </div>
    </div>
  );
}

export function BenchmarkSelectSingle(props) {
  const { value, values, onChange, placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "input-icon";
  if (focus || value !== "") iconType = "input-icon-focus";

  return (
    <div className="input-container">
      <div className={iconType}>{icon}</div>
      <div className="input-col">
        {placeholder}
        <div className="input-row">
          <select
            className="b-c-search-input"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            {selectOptions(values)}
          </select>
        </div>
      </div>
    </div>
  );
}

function selectOptions(values) {
  return values.map((item, i) => {
    return (
      <option key={i} value={item.value} className="option">
        {item.label}
      </option>
    );
  });
}

export function BenchmarkSelectSingleFilter(props) {
  const { value, values, onChange, placeholder, icon, filter } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "input-icon";
  if (focus || value !== "") iconType = "input-icon-focus";

  return (
    <div className="input-container">
      <div className={iconType}>{icon}</div>
      <div className="input-col">
        {placeholder}
        <div className="input-row">
          <select
            className="b-c-search-input"
            value={value}
            onChange={(e) => {
              onChange(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
          >
            {selectOptionsFilter(values, filter)}
          </select>
        </div>
      </div>
    </div>
  );
}

function selectOptionsFilter(values, filter) {
  return values.map((item, i) => {
    if (item.type === filter || item.type === "") {
      return (
        <option key={i} value={item.value} className="option">
          {item.label}
        </option>
      );
    } else {
      return null;
    }
  });
}

export function BenchmarkInputDouble(props) {
  const { value1, onChange1 } = props;
  const { value2, onChange2 } = props;
  const { placeholder, icon } = props;
  const [focus, setFocus] = useState(false);
  let iconType = "input-icon";
  if (focus || value1 !== "" || value2 !== "") iconType = "input-icon-focus";

  return (
    <div className="input-container">
      <div className={iconType}>{icon}</div>
      <div className="input-col">
        {placeholder}
        <div className="input-row">
          <input
            className="b-c-search-input-pair"
            type="text"
            value={value1}
            onChange={(e) => {
              onChange1(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
          <input
            className="b-c-search-input-pair-second"
            type="text"
            value={value2}
            onChange={(e) => {
              onChange2(e.target.value);
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            autoComplete="off"
          ></input>
        </div>
      </div>
    </div>
  );
}

export function DeleteBenchmark(props) {
  const { selectedBenchmarks, setBenchmarking } = props;
  let selection = selectedBenchmarks.map((b) => b.id);

  return (
    <button
      className="benchmark-control-delete-button"
      onClick={() => {
        const confirm = window.confirm(
          "Confirm delete " +
            selection.length +
            " benchmark project(s)? This cannot be undone."
        );
        if (confirm) {
          deleteBenchmarking(selection, setBenchmarking);
        }
      }}
    >
      <i className="far fa-trash-alt"></i>
    </button>
  );
}

export function FlagBenchmark(prop) {
  const { selectedBenchmarks } = prop;
  const { setBenchmarking } = prop;
  const { setShow } = prop;
  const { feature } = prop;
  const [error, setError] = useState({});

  const [infoSensLevel, setInfoSensLevel] = useState("");
  const { sensitivityLevelCodes } = useSensitivityLevelCodes();
  const secLevelOptions = [{ label: "Select Case", value: "" }];

  const [companyId, setCompanyId] = useState("");
  const [coordinates, setCoordinates] = useState({});

  sensitivityLevelCodes?.data?.forEach((c) => {
    secLevelOptions.push({ value: c.value, label: c.label });
  });
  let selection = selectedBenchmarks.map((b) => b.id);

  let data = {
    selection: selection,
    setModal: setShow,
    setBenchmarking: setBenchmarking,
    setError: setError,
    sensitivityFlag: infoSensLevel,
    companyId: companyId,
    coordinates: coordinates,
    feature: feature,
  };

  return (
    <div className="benchmark-upload">
      <div className="benchmark-upload-control-container">
        <div className="benchmark-upload-control">
          <TriggerBenchmarkInfoModal
            feature={feature}
            setInfoSensLevel={setInfoSensLevel}
            setCompanyId={setCompanyId}
            setCoordinates={setCoordinates}
          />
        </div>
      </div>
      <div className="general-row-container">
        <Errors error={error} setError={setError} />
      </div>
      <div className="general-button-container">
        <input
          type="button"
          className="general-upload-button"
          onClick={() => {
            switch (feature) {
              case featureEnumerator.COMPANY:
                saveSensitivityCompanyId(data);
                break;
              case featureEnumerator.SENSITIVITY:
                saveSensitivityInformationFlag(data);
                break;
              case featureEnumerator.COORDINATES:
                saveBenchmarkCoordinates(data);
                break;
              default:
                break;
            }
          }}
          value="Save"
        />
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
TriggerBenchmarkInfoModal.propTypes = {
  feature: PropTypes.number,
  setInfoSensLevel: PropTypes.func,
  setCompany: PropTypes.func,
  setCompanyId: PropTypes.func,
  setCoordinates: PropTypes.func,
};

function TriggerBenchmarkInfoModal(props) {
  const { feature } = props;
  const { setInfoSensLevel } = props;
  const { setCompany } = props;
  const { setCompanyId } = props;
  const { setCoordinates } = props;

  switch (feature) {
    case featureEnumerator.SENSITIVITY:
      return (
        <InformationSensitivityComponent setInfoSensLevel={setInfoSensLevel} />
      );
    case featureEnumerator.COMPANY:
      return (
        <BenchmarkCompanyComponent
          setCompany={setCompany}
          setCompanyId={setCompanyId}
        />
      );
    case featureEnumerator.COORDINATES:
      return <BenchmarkLocationComponent setCoordinates={setCoordinates} />;
    default:
      return <div>No component</div>;
  }
}

/// props validation | SQ(javascript:S6774)
InformationSensitivityComponent.propTypes = {
  setInfoSensLevel: PropTypes.func,
};
function InformationSensitivityComponent(props) {
  const { setInfoSensLevel } = props;
  const { sensitivityLevelCodes } = useSensitivityLevelCodes();
  const secLevelOptions = [{ label: "Select Case", value: "" }];

  sensitivityLevelCodes?.data?.forEach((c) => {
    secLevelOptions.push({ value: c.value, label: c.label });
  });

  return (
    <div className="benchmark-upload-control-row">
      <div className="benchmark-upload-control-label">Level Of Security:</div>
      <select
        className="benchmark-upload-control-input"
        onChange={(e) => {
          setInfoSensLevel(e.target.value);
        }}
      >
        {selectType(secLevelOptions)}
      </select>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
BenchmarkCompanyComponent.propTypes = {
  setCompanyId: PropTypes.func,
};

function BenchmarkCompanyComponent(props) {
  const { setCompanyId } = props;
  const [company, setCompany] = useState({});
  return (
    <div className="benchmark-upload-control-row">
      <div className="benchmark-upload-control-label">Select Company:</div>
      <ModalCompany
        Component={CreateProjectSelectClientCompany}
        setFunction={(company) => {
          setCompany(company);
          setCompanyId(company.id);
        }}
        label={<i className="fas fa-building"></i>}
        company={company}
        error={""}
        title={"Client Company"}
        isProjectCreation={true}
      />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
BenchmarkLocationComponent.propTypes = {
  setCoordinates: PropTypes.func,
};
function BenchmarkLocationComponent(props) {
  const { setCoordinates } = props;
  return (
    <div className="benchmark-upload-control-row">
      <ProjectLocation setCoordinates={setCoordinates} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
ExportBenchmark.propTypes = {
  selectedBenchmarks: PropTypes.array,
};

export function ExportBenchmark(props) {
  const { selectedBenchmarks } = props;
  const excelFileName = "benchmark";

  let bmIds = selectedBenchmarks.map((bm) => {
    return {
      benchmark_id: bm.id,
    };
  });
  let { benchmarksWithRows } = useBenchmarksWithRows(bmIds);

  let { TotalSheets, sheetNames } =
    prepareObjectForExcelUpload(benchmarksWithRows);
  return (
    <button
      className="benchmark-control-export-button"
      onClick={() => {
        if (selectedBenchmarks && selectedBenchmarks.length > 0) {
          const confirm = window.confirm(
            "Confirm export " +
              selectedBenchmarks.length +
              " benchmark project(s)?"
          );
          if (confirm) {
            generateBenchmarkInfoExcel(excelFileName, TotalSheets, sheetNames);
          }
        } else {
          window.alert("Please select a benchmark to export.");
        }
      }}
    >
      <i className="fa fa-download" aria-hidden="true"></i>
    </button>
  );
}

function Errors(prop) {
  const { error } = prop;
  return (
    <div className="account-edit-error-container">
      <div className={error.type}>
        <b>{error.text}</b>
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
ProjectLocation.propTypes = {
  setCoordinates: PropTypes.func,
};

export function ProjectLocation(props) {
  const { setCoordinates } = props;
  const [lng, setLng] = useState("0");
  const [lat, setLat] = useState("0");
  const [errors] = useState({});

  useEffect(() => {
    setCoordinates({ lng: lng, lat: lat });
  }, [lng, lat, setCoordinates]);

  return (
    <div className="create-project-location-wrapper">
      <div className="create-project-location-container">
        <FieldNumber
          label={translate("Latitude")}
          placeholder={tranStr("Latitude")}
          value={lat}
          onChange={setLat}
          error={errors.Lat}
        />
        <FieldNumber
          label={translate("Longitude")}
          placeholder={tranStr("Longitude")}
          value={lng}
          onChange={setLng}
          error={errors.Lng}
        />
      </div>
      <CreateProjectMap lat={lat} lng={lng} />
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
BasicBenchmark.propTypes = {};
export function BasicBenchmark(props) {
  return <BasicBenchmarkForms {...props} />;
}

/// props validation | SQ(javascript:S6774)
BasicBenchmarkForms.propTypes = {
  selectedBenchmarks: PropTypes.array,
  setShow: PropTypes.func,
  setBenchmarking: PropTypes.func,
};
export function BasicBenchmarkForms(props) {
  const { selectedBenchmarks, setShow, setBenchmarking } = props;
  const tabNames = [
    "projectInfo",
    "elementAnalysis",
    "benchmarks",
    "notes",
    "Cost Plan",
    "Visuals",
  ];

  const [tabValue, setTabValue] = React.useState(tabNames[0]);
  const [error, setError] = useState({});

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const [basicBm, setBasicBm] = useState(
    !isEmpty(selectedBenchmarks) ? selectedBenchmarks[0] : {}
  );

  if (!isEmpty(selectedBenchmarks) && selectedBenchmarks?.length > 1)
    return <div>Please choose only one benchmark to edit</div>;

  let data = {
    setModal: setShow,
    setBenchmarking: setBenchmarking,
    setError: setError,
    basicBm: basicBm,
  };

  const SaveFormBasedBenchmark = () => {
    saveFormBasedBenchmark(data);
  };
  return (
    <div className="basic-benchmark-container">
      <Box className="bm-full-width-box-container">
        <Box className="bm-tab-box-container">
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            centered
            aria-label="Benchmark view tabs"
            TabIndicatorProps={{
              style: {
                backgroundColor: "var(--heritage)",
              },
            }}
          >
            <Tab
              label="Project Information"
              value={tabNames[0]}
              style={{ outline: "none" }}
            />
          </Tabs>
        </Box>
        <BenchmarkCustomTabPanel value={tabValue} index={tabNames[0]}>
          <BasicBenchmarkProject
            setBasicBm={setBasicBm}
            basicBm={basicBm}
            error={error}
          />
        </BenchmarkCustomTabPanel>
        <BenchmarkCustomTabPanel value={tabValue} index={tabNames[1]}>
          <div>No Data Available</div>
        </BenchmarkCustomTabPanel>
      </Box>
      <div className="basic-benchmark-footer">
        {isEmpty(selectedBenchmarks) && (
          <button
            id="basic-benchmark-save-button"
            className="general-upload-button"
            onClick={async () => {
              SaveFormBasedBenchmark();
            }}
          >
            {translate("Save")}
          </button>
        )}
      </div>
    </div>
  );
}

/// props validation | SQ(javascript:S6774)
BasicBenchmarkProject.propTypes = {
  basicBm: PropTypes.object,
  setBasicBm: PropTypes.func,
  error: PropTypes.object,
};
export function BasicBenchmarkProject(props) {
  const { basicBm, setBasicBm } = props;
  const { error } = props;
  // API
  const { locations } = useLocations(true);
  const [region, setRegion] = useState(basicBm.region);
  const [country, setCountry] = useState(basicBm.country);
  const [city, setCity] = useState(basicBm.city);
  const [projectName, setProjectName] = useState(basicBm.project_name);

  useEffect(() => {
    basicBm.country = country;
    basicBm.city = city;
    basicBm.region = region;
    basicBm.project_name = projectName;
    setBasicBm(basicBm);
  }, [city, country, region, projectName,basicBm,setBasicBm]);

  return (
    <div className="create-project-panel">
      <div className="create-project-location-wrapper">
        <Field
          label={translate("Project Name")}
          placeholder={tranStr("Project Name")}
          value={projectName}
          onChange={setProjectName}
          classType={"create-project-location-input"}
        />
        <Label label={translate("Region")} />
        <RegionSelect
          value={region}
          set={setRegion}
          options={locations.data}
          classType={"create-project-location-select"}
          setCountry={setCountry}
          setCity={setCity}
        />

        <Label label={translate("Country")} />
        <CountrySelect
          value={country}
          set={setCountry}
          options={locations.data}
          classType={"create-project-location-select"}
          region={region}
          setCity={setCity}
        />
        <Label label={translate("City")} />
        <CitySelect
          value={city}
          set={setCity}
          options={locations.data}
          classType={"create-project-location-select"}
          country={country}
        />
      </div>
      <Errors error={error} />
    </div>
  );
}
